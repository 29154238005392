import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";

import {AjaxDatatables, AlternativePagination, BasicTable, FixedHeaderDatatables, ModalDataDatatables, ScrollHorizontal, ScrollVertical} from "../Tables/DataTables/datatableCom";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import API from '../../components/API';

const Settlements = () => {
  document.title = "Rozliczenia";

  // let { type } = useParams();

  const [data, setData] = useState([]);

const refresh = async() => {
  // let t = type == 'doctors' ? 'doctor' : 'patient';
  API.call('get', 'settlements').then(data => {
    console.log(data)
    setData(data)
  }) 
}

  useEffect(() => {
    refresh();
  }, [])

const columns = [
  // {
  //     name: <Input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
  //     cell: () => (
  //         <input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />
  //     ),
  // },
  {
    name: <span className='font-weight-bold fs-13'>ID</span>,
    selector: row => row.id,
    sortable: true
  },
  {
      name: <span className='font-weight-bold fs-13'>Data rozliczenia</span>,
      selector: row => row.date,
      sortable: true
  },
  {
      name: <span className='font-weight-bold fs-13'>Kwota roliczenia</span>,
      selector: row => row.amount,
      sortable: true
  },
  {
    name: <span className='font-weight-bold fs-13'>Lekarz</span>,
    selector: row => row.name + ' ' + row.surname,
    sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>Status</span>,
  sortable: true,
  selector: (cell) => {
    // return <span className="badge bg-success"> wypłacone </span>;
      switch (cell.status) {
          case 0:
              return <span className="badge bg-danger"> zapłacona </span>;
          case "Medium":
              return <span className="badge bg-info"> {cell.priority} </span>;
          case 1:
              return <span className="badge bg-success"> niezapłacona </span>;
          default:
              return <span className="badge bg-danger"> {cell.priority} </span>;
      }
  },
},
// {
//   name: <span className='font-weight-bold fs-13'>Pacjent</span>,
//   selector: row => row.patient.name + ' ' + row.patient.surname,
//   sortable: true
// },
// {
//   name: <span className='font-weight-bold fs-13'>Kwota konsultacji</span>,
//   selector: row => row.price + 'PLN',
//   sortable: true
// },
  // {
  //     name: <span className='font-weight-bold fs-13'>ID</span>,
  //     selector: row => row.id,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Purchase ID</span>,
  //     selector: row => row.purchaseId,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Title</span>,
  //     selector: row => (<Link to="#!">{row.title}</Link>),
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>User</span>,
  //     selector: row => row.user,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Assigned To</span>,
  //     selector: row => row.assigned,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Create By</span>,
  //     selector: row => row.createdBy,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Create Date</span>,
  //     selector: row => row.createDate,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Status</span>,
  //     sortable: true,
  //     selector: (cell) => {
        
  //         switch (cell.status) {
  //             case "Re-open":
  //                 return <span className="badge badge-soft-info"> {cell.status} </span>;
  //             case "On-Hold":
  //                 return <span className="badge badge-soft-secondary"> {cell.status} </span>;
  //             case "Closed":
  //                 return <span className="badge badge-soft-danger"> {cell.status} </span>;
  //             case "Inprogress":
  //                 return <span className="badge badge-soft-warning"> {cell.status} </span>;
  //             case "Open":
  //                 return <span className="badge badge-soft-primary"> {cell.status} </span>;
  //             case "New":
  //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
  //             default:
  //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
  //         }
  //     },
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Status</span>,
  //     sortable: true,
  //     selector: (cell) => {
  //       return <span className="badge bg-success"> zapłacona </span>;
  //         // switch (cell.priority) {
  //         //     case "High":
  //         //         return <span className="badge bg-danger"> {cell.priority} </span>;
  //         //     case "Medium":
  //         //         return <span className="badge bg-info"> {cell.priority} </span>;
  //         //     case "Low":
  //         //         return <span className="badge bg-success"> {cell.priority} </span>;
  //         //     default:
  //         //         return <span className="badge bg-danger"> {cell.priority} </span>;
  //         // }
  //     },
  // },
  {
      name: <span className='font-weight-bold fs-13'>Action</span>,
      sortable: true,

      cell: () => {
          return (
            <button
            type="button"
            onClick={() => {
              API.call('get', 'settlements/pdf').then(result => {
                const link = document.createElement('a');
                link.download = 'invoice';

                link.href = 'https://api-med.code-me.pl/storage/' + result;

                link.click();
              }) 
            }}
            className="btn btn-primary waves-effect waves-light"
          >
            Pobierz PDF
          </button>
          );
      },
  },
];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Rozliczenia" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Settlements;
