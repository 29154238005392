import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";




const LineColumnArea = (props) => {

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Odbytych rozmów",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 18],
      },
      {
        name: "Dochód",
        type: "area",
        data: [44, 55, 41, 42, 22, 43, 21, 41, 56, 27, 43, 27],
      },
      {
        name: "Prowizja",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
      },
    ],
    options: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0.5, 1],
        curve: "smooth",
        dashArray: [0, 8, 5]
      },
      plotOptions: {
        bar: {
          columnWidth: "18%",
        },
      },
      colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],
  
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      markers: {
        size: 0,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "month",
      },
      yaxis: {
        title: {
          text: "Points",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(2)
            }
            return y
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  });

  useEffect(() => {
    // console.log(props);
    // console.log(props.chart);
    if (props.chart != undefined && props.chart.length != 0) {
      let chData = chartData;
      chData.options.labels = props.chart.labels;
      chData.series[0].data = props.chart.series[0];
      chData.series[1].data = props.chart.series[1];
      chData.series[2].data = props.chart.series[2];
      setChartData(null);
      setTimeout(() => {
        setChartData(chData);
      }, 1);
      
      console.log(props.chart)
      // LineColumnAreaData.options.labels = props?.chart?.labels;
      // LineColumnAreaData.series[0].data = props?.chart?.series[0];
    }
    // LineColumnAreaData.series[0].data = props?.chart?.series[0];
    // LineColumnAreaData.series[1].data = props?.chart?.series[1];
    // LineColumnAreaData.series[2].data = props?.chart?.series[2];
    // LineColumnAreaData.options.labels = props?.chart?.labels;

    console.log(chartData); 
  }, [props.chart])

  if (chartData != null)
  return(
    <React.Fragment>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height="350"
          stacked= "false"
          className="apex-charts"
        />
      </React.Fragment>
  )
}

export default LineColumnArea;