import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";

import {AjaxDatatables, AlternativePagination, BasicTable, FixedHeaderDatatables, ModalDataDatatables, ScrollHorizontal, ScrollVertical} from "../Tables/DataTables/datatableCom";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import API from '../../components/API';

const Users = () => {
  document.title = "Klienci";

  let { type } = useParams();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);

const refresh = async() => {
  let t = type == 'doctors' ? 'doctor' : 'patient';
  API.call('get', 'users/' + t).then(data => {
    console.log(data)
    setData(data)
  }) 
}

  useEffect(() => {
    refresh();
  }, [])
  useEffect(() => {
    refresh();

    // data.filter(obj => {
    //   console.log('aa');
    //   Object.values(obj).some(val => {
    //     console.log(String(val.includes(search))
    //   })
    // })

  }, [type])


const columns = [
  // {
  //     name: <Input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
  //     cell: () => (
  //         <input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />
  //     ),
  // },
  {
    name: <span className='font-weight-bold fs-13'>Id</span>,
    selector: row => row.id,
    sortable: true
  },
  {
      name: <span className='font-weight-bold fs-13'>Imię</span>,
      selector: (cell) => {
        let tt = type == 'doctors' ? 'doctor' : 'patient';
        let url = '/user/' + tt + '/' + cell.id; 
        return <Link to={url}>{cell.name} {cell.surname}</Link>
      },
      sortable: true
  },
  {
    name: <span className='font-weight-bold fs-13'>E-mail</span>,
    selector: row => row.email,
    sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>Telefon</span>,
  selector: row => row.phone,
  sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>Utworzono</span>,
  selector: row => row.created_at,
  sortable: true
},
  // {
  //     name: <span className='font-weight-bold fs-13'></span>,
  //     selector: (cell) => {
  //       let tt = type == 'doctors' ? 'doctor' : 'patient';
  //       let url = '/user/' + tt + '/' + cell.id; 
  //       return <Link className="btn btn-info waves-effect waves-light" to={url}>Profil</Link>
  //     },
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Purchase ID</span>,
  //     selector: row => row.purchaseId,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Title</span>,
  //     selector: row => (<Link to="#!">{row.title}</Link>),
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>User</span>,
  //     selector: row => row.user,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Assigned To</span>,
  //     selector: row => row.assigned,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Create By</span>,
  //     selector: row => row.createdBy,
  //     sortable: true
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Create Date</span>,
  //     selector: row => row.createDate,
  //     sortable: true
  // },
  {
      name: <span className='font-weight-bold fs-13'>Status</span>,
      sortable: true,
      selector: (cell) => {
        
          switch (cell.active) {
              case "Re-open":
                  return <span className="badge badge-soft-info"> {cell.status} </span>;
              case "On-Hold":
                  return <span className="badge badge-soft-secondary"> {cell.status} </span>;
              case 0:
                  return <span className="badge badge-soft-danger"> nieaktywny </span>;
              case "Inprogress":
                  return <span className="badge badge-soft-warning"> {cell.status} </span>;
              case "Open":
                  return <span className="badge badge-soft-primary"> {cell.status} </span>;
              case 1:
                  return <span className="badge badge-soft-success"> aktywny </span>;
              default:
                  return <span className="badge badge-soft-success"> {cell.active} </span>;
          }
      },
  },
  // {
  //     name: <span className='font-weight-bold fs-13'>Priority</span>,
  //     sortable: true,
  //     selector: (cell) => {
  //         switch (cell.priority) {
  //             case "High":
  //                 return <span className="badge bg-danger"> {cell.priority} </span>;
  //             case "Medium":
  //                 return <span className="badge bg-info"> {cell.priority} </span>;
  //             case "Low":
  //                 return <span className="badge bg-success"> {cell.priority} </span>;
  //             default:
  //                 return <span className="badge bg-danger"> {cell.priority} </span>;
  //         }
  //     },
  // },
  // {
  //     name: <span className='font-weight-bold fs-13'>Action</span>,
  //     sortable: true,

  //     cell: () => {
  //         return (
  //             <UncontrolledDropdown className="dropdown d-inline-block">
  //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
  //                     <i className="ri-more-fill align-middle"></i>
  //                 </DropdownToggle>
  //                 <DropdownMenu className="dropdown-menu-end">
  //                     <DropdownItem href="#!"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
  //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
  //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
  //                 </DropdownMenu>
  //             </UncontrolledDropdown>
  //         );
  //     },
  // },
];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Użytkownicy" breadcrumbItem="Klienci" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>


                  <Row>
                    <Col className="col-8">

                    </Col>
                    <Col className="col-4">
                      <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={setSearch}
                      />
                    </Col>
                  </Row>
                

                <DataTable
                  columns={columns}
                  data={(search == '' ? data : data.filter(obj => Object.values(obj).some(val => String(val).includes(search))))}
                  pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Users;
