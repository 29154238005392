
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { Link, useParams } from "react-router-dom";
import API from '../../components/API';
import DataTable from "react-data-table-component";

const User = () => {
  document.title = "Dane użytkownika";

  let {type, userId} = useParams();
  const[data, setData] = useState([]);
  const[conversations, setConversations] = useState([]);
  const[blockadeModal, setBlockadeModel] = useState(false);
  const refresh = () => {
    API.call('get', 'user/' + userId).then(data => {
      console.log(data)
      setData(data)
    }) 
    API.call('get', 'userConversations/' + type + '/' + userId).then(data => setConversations(data)) 
  }
  useEffect(() => {
    refresh()
  }, [userId]);

  const fields = [
    {
      title : 'Dane osobowe',
      fields : [{
        label: "Imię",
        type : "text",
        icon : "person",
        var : "name",
      }, {
        label: "Nazwisko",
        type : "text",
        icon : "person",
        var : "surname",
      }, {
        label: "Pesel",
        type : "text",
        icon : "person",
        var : "pesel",
      }]
    },
    {
      title : 'Adres',
      fields : [{
        label: "Ulica",
        type : "text",
        icon : "point",
        var : "street",
      }, {
        label: "Nr lokalu",
        type : "text",
        icon : "point",
        var : "street_number",
      },
      {
        label: "Miasto",
        type : "text",
        icon : "point",
        var : "city",
      },
      {
        label: "Kod pocztowy",
        type : "text",
        icon : "point",
        var : "postcode",
      }]
    },
    {
      title : 'Kontakt',
      fields : [{
        label: "Telefon",
        type : "text",
        icon : "person",
        var : "phone",
      }, {
        label: "E-mail",
        type : "text",
        icon : "person",
        var : "email",
      }]
    },
    // {
    //   title : 'Zdjęcie profilowe',
    //   fields : [{
    //     label: "Telefon",
    //     type : "image",
    //     icon : "",
    //     var : "profile-photo",
    //   }]
    // },
    {
      title : 'Karta',
      fields : [
        {
        label: "Nr karty",
        type : "text",
        icon : "person",
        var : "card_number",
      }, {
        label: "Data",
        type : "text2",
        icon : "person",
        var : "card_date",
      },
      {
        label: "CCV",
        type : "hidden",
        icon : "person",
        var : "card_ccv",
      }, {
        label: "Imię",
        type : "text",
        icon : "person",
        var : "card_name",
      },
      {
        label: "Nazwisko",
        type : "text",
        icon : "person",
        var : "card_name",
      }
    ]
    }
  ];

//   const dispatch = useDispatch();

//   const [email, setemail] = useState("");
//   const [name, setname] = useState("");
//   const [idx, setidx] = useState(1);

//   const { error, success } = useSelector((state) => ({
//     error: state.profile.error,
//     success: state.profile.success,
//   }));

//   useEffect(() => {
//     if (localStorage.getItem("authUser")) {
//       const obj = JSON.parse(localStorage.getItem("authUser"));
//       if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//         setname(obj.displayName);
//         setemail(obj.email);
//         setidx(obj.uid);
//       } else if (
//         process.env.REACT_APP_DEFAULTAUTH === "fake" ||
//         process.env.REACT_APP_DEFAULTAUTH === "jwt"
//       ) {
//         setname(obj.username);
//         setemail(obj.email);
//         setidx(obj.uid);
//       }
//       setTimeout(() => {
//         dispatch(resetProfileFlag());
//       }, 3000);
//     }
//   }, [dispatch, success]);

//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       username: name || "",
//       idx: idx || "",
//     },
//     validationSchema: Yup.object({
//       username: Yup.string().required("Please Enter Your UserName"),
//     }),
//     onSubmit: (values) => {
//       dispatch(editProfile(values));
//     },
//   });

const columns = [{
    name: <span className='font-weight-bold fs-13'>Data rozmowy</span>,
    selector: row => row.date,
    sortable: true
},
{
    name: <span className='font-weight-bold fs-13'>Czas rozmowy</span>,
    selector: row => row.time,
    sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>Lekarz</span>,
  selector: (cell) => {
    let tt = 'doctor';
    let url = '/user/' + tt + '/' + cell.doctor.name; 
    return <Link to={url}>{cell?.doctor?.name} {cell.doctor.surname}</Link>
  },
  sortable: true
},
{
name: <span className='font-weight-bold fs-13'>Pacjent</span>,
selector: row => row.patient.name + ' ' + row.patient.surname,
sortable: true
},
{
name: <span className='font-weight-bold fs-13'>Kwota konsultacji</span>,
selector: row => row.price + 'PLN',
sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>Status</span>,
  sortable: true,
  selector: (cell) => {
    return <span className="badge bg-success"> zapłacona </span>;
}},
{
  name: <span className='font-weight-bold fs-13'>Action</span>,
  sortable: true,

  cell: () => {
      return (
        <button
        type="button"
        onClick={() => {
          API.call('get', 'conversations/pdf').then(result => {
            const link = document.createElement('a');
            link.download = 'invoice';

            link.href = 'https://api-med.code-me.pl/storage/' + result;

            link.click();
          }) 
        }}
        className="btn btn-primary waves-effect waves-light"
      >
        Pobierz PDF
      </button>
      );
  }
}];

const disableUser = () => {
  API.call('post', 'blockadeUser/' + userId).then(data => {
    refresh();
    setBlockadeModel(!blockadeModal);
  }) 
}

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Upzet" breadcrumbItem="Profile" />

{
  !data.active && <Row><Col lg="12">
 <div class="alert alert-danger mb-0" role="alert">Konto tego użytkownika jest zablokowane!</div>
 </Col></Row>
}
                
            

            <Row>
              <Col lg="2">
                <Card>
                  <CardBody>
                    <div className="text-center">
                    <img
                        
                        src={'https://api-med.code-me.pl/storage/' + data['profile-photo']}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                      <br></br>
                      <h4>{data['name']} {data['surname']}</h4>
                      <span>{data['email']}</span>
                      {
                        data.active && 
                        <button type="button" style={{marginTop : '10px'}}
                        className="btn btn-danger waves-effect" 
                        onClick={() => setBlockadeModel(!blockadeModal)}>
                          Zablokuj użytkownika
                        </button>
                      }
                      {
                        !data.active && 
                        <button type="button" style={{marginTop : '10px'}}
                        className="btn btn-primary waves-effect" 
                        onClick={() => setBlockadeModel(!blockadeModal)}>
                          Odblokuj użytkownika
                        </button>
                      }

                      
                    </div>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          {
                            fields.map((item, itemKey) => {
                              return (
                                // <Row><h5>{item.title}</h5></Row>
                                <Row>
                                  <h3>{item.title}</h3>
                                  <Row>
                                  {
                                    item.fields.map((field, fieldKey) => {
                                      return (
                                        <Col lg="4">
                                          <div>
                          <label className="form-label">{field.label}</label>
                          <input
                            className="form-control"
                            type="text"
                            value={data[field.var]}
                          />
                        </div>
                                          {/* <label>{field.label}: </label>&nbsp;
                                          <span>{data[field.var]}</span> */}
                                        </Col>
                                      )
                                    })
                                  }
                                  </Row>
                                </Row>
                                
                              )
                            })
                          }
                        </div>
                        {/* <div className="text-muted">
                          <h5>{data?.name} {data?.surname}</h5>
                          <p className="mb-1">{data?.email}</p>
                          <p className="mb-0">#{userId}</p>
                        </div> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Konsultacje użytkownika</h4>

            <Card>
                <CardBody>
                <DataTable
                  columns={columns}
                  data={conversations}
                  pagination
                  />
                </CardBody>
            </Card>


          </Container>

          

          <Modal
                              isOpen={blockadeModal}
                              toggle={() => {
                                setBlockadeModel(!blockadeModal);
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  { data.active ? 'Blokowanie użytkownika' : 'Odblokowanie użytkownika' }
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setBlockadeModel(!blockadeModal);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <h4>
                                  Czy na pewno chcesz { data.active ? 'zablokować' : 'odblokować' } konto wybranego użytkownika?
                                </h4>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setBlockadeModel(!blockadeModal);
                                  }}
                                  className="btn btn-secondary "
                                  data-dismiss="modal"
                                >
                                  Anuluj
                                </button>
                                <button
                                  type="button"
                                  className={"btn " + (data.active ? "btn-danger" : "btn-primary")}
                                  onClick={disableUser}
                                >
                                  { data.active ? 'Zablokuj' : 'Odblokuj' } użytkownika
                                </button>
                              </div>
                            </Modal>
        </div>
    </React.Fragment>
  );
};

export default withRouter(User);
