import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";

import {AjaxDatatables, AlternativePagination, BasicTable, FixedHeaderDatatables, ModalDataDatatables, ScrollHorizontal, ScrollVertical} from "../Tables/DataTables/datatableCom";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import API from '../../components/API';

const Settings1 = () => {
  document.title = "Ustawienia systemowe";

  const token = 'CJERouh0Rcp6rgEuY29aqLnFViMq5dX6FZlQJv9S';
  const password = {min : 6, numbers : 1, uppercase : 1}

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Ustawienia" breadcrumbItem="Systemowe" />

          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <label>Minimalna liczba znaków</label>
                  <input type="text" className="form-control" value={password.min} />
                  <label>W tym cyfr</label>
                  <input type="text" className="form-control" value={password.numbers} />
                  <label>W tym dużych liter</label>
                  <input type="text" className="form-control" value={password.uppercase} />
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <label>Token SMSAPI</label>
                  <input type="text" className="form-control" value={token} />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};
export default Settings1;
