import axios from 'axios'

class API {

    static call (method = 'post', endpoint, data = null) {
        let { REACT_APP_REST_URI } = process.env;

        return new Promise((resolve, reject) => {
            axios({
                method : method,
                url : REACT_APP_REST_URI + 'api/v1/' + endpoint,
                data : data
            })
            .then(data => {
                console.log('--- DATA ---')
                console.log(data)
                console.log('--- !DATA ---')
               if (data.error == undefined) {
                resolve(data.data);
               }
            });
        })
    

    }

}

export default API;